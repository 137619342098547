document.addEventListener('DOMContentLoaded', function () {

    // Attach event listeners to carousel elements within a given modal
    function attachCarouselListeners(modal) {
        // Select all carousels within the modal
        const carousels = modal.querySelectorAll('.image-carousel');

        // Loop through each carousel
        carousels.forEach(function (carousel, index) {
            // Select all images within the carousel
            let images = carousel.querySelectorAll('.carousel-image');
            let currentIndex = 0;

            function showImage(index, direction) {
                images.forEach((img, i) => {
                    // img.style.opacity = '0';
                    img.classList.remove('active');
                    img.classList.remove('slide-in-left', 'slide-in-right');
                });

                if (direction === 'left') {
                    images[index].classList.add('slide-in-left');
                } else if (direction === 'right') {
                    images[index].classList.add('slide-in-right');
                }

                // images[index].style.opacity = '1';
                images[index].classList.add('active')
            }

            let prevButton = carousel.querySelector('.carousel__prev_arrow');
            let nextButton = carousel.querySelector('.carousel__next_arrow');

            if (prevButton && nextButton) {
                // Remove arrows if there is only one image
                if (images.length === 1) {
                    prevButton.style.display = 'none';
                    nextButton.style.display = 'none';
                }
                prevButton.addEventListener('click', function (event) {
                    event.preventDefault();
                    currentIndex = (currentIndex > 0) ? currentIndex - 1 : images.length - 1;
                    showImage(currentIndex, 'left');
                });

                prevButton.addEventListener('touchend', function (event) {
                    event.preventDefault();
                    currentIndex = (currentIndex > 0) ? currentIndex - 1 : images.length - 1;
                    showImage(currentIndex, 'left');
                });

                nextButton.addEventListener('click', function (event) {
                    event.preventDefault();
                    currentIndex = (currentIndex < images.length - 1) ? currentIndex + 1 : 0;
                    showImage(currentIndex,'right' );
                });

                nextButton.addEventListener('touchend', function (event) {
                    event.preventDefault();
                    currentIndex = (currentIndex < images.length - 1) ? currentIndex + 1 : 0;
                    showImage(currentIndex, 'right');
                });

                // Initially show the first image
                showImage(currentIndex);
            }

            // Variables to store touch positions
            let startX = 0;
            let endX = 0;

            // Add touch event listeners for swipe functionality
            carousel.addEventListener('touchstart', function (event) {
                startX = event.touches[0].clientX;
            });

            carousel.addEventListener('touchmove', function (event) {
                endX = event.touches[0].clientX;
            });

            carousel.addEventListener('touchend', function (event) {
                if (endX === 0) return;
                if (startX > endX + 30) { // Swipe left
                    currentIndex = (currentIndex < images.length - 1) ? currentIndex + 1 : 0;
                    showImage(currentIndex, 'right');
                } else if (startX < endX - 30) { // Swipe right
                    currentIndex = (currentIndex > 0) ? currentIndex - 1 : images.length - 1;
                    showImage(currentIndex, 'left');
                }
                endX = 0;
            });
        });
    }

    function toggleBio(event) {
        event.preventDefault();
        let button = event.currentTarget;
        let bio = button.closest('.modal').querySelector('.trainer-bio');
        if (bio.getAttribute('data-active') === 'false') {
            button.textContent = 'Close Bio';
            bio.setAttribute('data-active', 'true');
        } else {
            button.textContent = 'Read Bio';
            bio.setAttribute('data-active', 'false');
        }
    }

    // Attach event listeners to toggle bio buttons
    function attachToggleBioListeners(modal) {
        modal.querySelectorAll('.toggle-bio').forEach(function (button) {
            button.addEventListener('click', toggleBio);
            button.addEventListener('touchend', toggleBio); // Add touch event listener
        });
    }

    // Detach event listeners from carousel elements within a given modal
    function detachCarouselListeners(modal) {
        const carousels = modal.querySelectorAll('.image-carousel');

        carousels.forEach(function (carousel) {
            let prevButton = carousel.querySelector('.carousel__prev_arrow');
            let nextButton = carousel.querySelector('.carousel__next_arrow');

            if (prevButton && nextButton) {
                // Replace the buttons with clones to remove existing event listeners
                prevButton.replaceWith(prevButton.cloneNode(true));
                nextButton.replaceWith(nextButton.cloneNode(true));
            }
        });
    }

    // Detach event listeners from toggle bio buttons within a given modal
    function detachToggleBioListeners(modal) {
        modal.querySelectorAll('.toggle-bio').forEach(function (button) {
            button.replaceWith(button.cloneNode(true));
        });
    }

    // Create a mutation observer to watch for changes in the modals class attribute
    const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            const modal = mutation.target;

            // Check if the modal has the 'show' class
            if (modal.classList.contains('show')) {
                detachCarouselListeners(modal); // Remove existing event listeners
                attachCarouselListeners(modal); // Attach new event listeners

                detachToggleBioListeners(modal);
                attachToggleBioListeners(modal);
            }
        });
    });

    // Select all modals and observe changes to their class attribute
    document.querySelectorAll('.modal').forEach(modal => {
        observer.observe(modal, {
            attributes: true,
            attributeFilter: ['class']
        });
    });
});
